import { Outlet } from "react-router-dom";

const AppLayout = () => {
    return <div style={{
        padding: '15px 0px 0px 0px'
    }}>
        <Outlet />
    </div>;
};

export default AppLayout;