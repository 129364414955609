import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios'
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

class Replace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'space',
            input: '',
            output: 'Please input value...',
            valid: true,
            variantClipboard: 'secondary',
            copied: false,
        };
        this.timeout = 0;
    
        this.handleChange = this.handleChange.bind(this);
        this.handleClickClipboard = this.handleClickClipboard.bind(this);
    }

    handleChange = (event) => {
        this.state.variantClipboard = 'secondary';

        if(this.timeout) clearTimeout(this.timeout);
        this.setState({[event.target.name]: event.target.value});
        this.timeout = setTimeout(() => {
            axios.post('/replace', 
            {
                type: this.state.type,
                input: this.state.input
            }).then(response => {
                this.setState({
                    output: response.data.output,
                    valid: response.data.valid,
                })
            }).catch((err) => {
                console.log(err);
            });
          }, 500);
    };

    handleClickClipboard() {
        navigator.clipboard.writeText(this.state.output);
        this.setState(() => ({
            copied: true,
            variantClipboard: 'success',
        }));
    }

    render() {
        return (
            <Container fluid>
                <div>
                    <h3>Newline</h3>                    
                    <Row>
                        <Form>
                            <Col>
                                <Form.Group className="mb-3" controlId="form.ControlTextareaInput">
                                    {['space','slash','colon','semi-colon'].map((name) => (
                                        <Form.Check
                                            inline
                                            defaultChecked={name === "space"}
                                            type="radio"
                                            id={`${name}`}
                                            label={`${name}`}
                                            name="type"
                                            value={`${name}`}
                                            onChange={this.handleChange}
                                        />
                                    ))}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="form.ControlTextareaInput">
                                    <Form.Label>Input</Form.Label>
                                    <Form.Control name="input" as="textarea" rows={10} onChange={this.handleChange} placeholder="apple&#13;banana&#13;melon"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="form.ControlTextareaOutput">
                                    <Form.Label>Output</Form.Label>
                                    <span> </span>
                                    <Button variant={this.state.variantClipboard} size="sm" className="mb-2" onClick={this.handleClickClipboard}>
                                        {this.state.copied ? <BsClipboardCheck /> : <BsClipboard />}
                                    </Button>
                                    <Form.Control as="textarea" rows={10} value={this.state.output} readOnly/>
                                </Form.Group>
                            </Col>
                        </Form>
                    </Row>
                </div>
           </Container>
        );
    }
}

export default Replace;