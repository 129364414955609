import './App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import AppLayout from './components/layout/AppLayout';
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import JSONPretty from './pages/json/JSONPretty';
import JSONToYAML from './pages/json/JSONToYAML';
import JSONExtractor from './pages/json/JSONExtractor';
import Replace from './pages/replace/Replace';
import GeneratePassword from './pages/generate/GeneratePassword';
import GenerateBase64 from './pages/generate/GenerateBase64';
import GenerateMillisecond from './pages/generate/GenerateMillisecond';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
        <Navbar />
        <Routes>
            <Route path='/' element={<AppLayout />}>
                <Route index element={<JSONPretty />} />
                <Route path='/json/pretty' element={<JSONPretty />} />
                <Route path='/json/yaml' element={<JSONToYAML />} />
                <Route path='/json/extractor' element={<JSONExtractor />} />
                <Route path='/replace' element={<Replace />} />
                <Route path='/generate/password' element={<GeneratePassword />} />
                <Route path='/generate/base64' element={<GenerateBase64 />} />
                <Route path='/generate/millisecond' element={<GenerateMillisecond />} />
                <Route path='/about' element={<About />} />
            </Route>
        </Routes>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
