import { Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';

const MyNavbar = () => {
    return <Navbar bg="dark" variant="dark" expand="lg">
            <Container fluid>
            <Navbar.Brand href="/">Format Pretty</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <NavDropdown title="JSON" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/json/pretty">JSON Pretty</NavDropdown.Item>
                        <NavDropdown.Item href="/json/yaml">JSON to YAML</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Generate" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/generate/password">Password Generator</NavDropdown.Item>
                        <NavDropdown.Item href="/generate/base64">Base64 Encoding & Decoding</NavDropdown.Item>
                        <NavDropdown.Item href="/generate/millisecond">Milliseconds to Date</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/replace">Replace</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
  </Navbar>;
};

export default MyNavbar;
