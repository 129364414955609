import React, { Component } from 'react';
import CommonPage from '../common/CommonPage'

function JSONToYAML() {
    return (
        <CommonPage 
            title='JSON to YAML'
            apiUrl='/json/yaml'
            inputPlaceHolder='{&#13;"type":"fruit",&#13;"name":"banana"&#13;}'
        />
    );
}

export default JSONToYAML;