import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

class CommonPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            output: 'Please input value...',
            valid: true,
            variantClipboard: 'secondary',
            copied: false,
        };
        this.timeout = 0;
    
        this.handleChange = this.handleChange.bind(this);
        this.handleClickClipboard = this.handleClickClipboard.bind(this);
    }

    handleChange = (event) => {
        this.state.variantClipboard = 'secondary';

        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            axios.post(this.props.apiUrl, 
            {
                input: event.target.value
            }).then(response => {
                this.setState({
                    output: response.data.output,
                    valid: response.data.valid,
                })
            }).catch((err) => {
                console.log(err);
            });
          }, 500);
    };

    handleClickClipboard() {
        navigator.clipboard.writeText(this.state.output);
        this.setState(() => ({
            copied: true,
            variantClipboard: 'success',
        }));
    }
    

    render() {
        const valid = this.state.valid;
        let alert;
        if (!valid) {
            alert = <Alert variant="danger" dismissible>
                        <Alert.Heading>You got an error</Alert.Heading>
                        {this.state.output}
                    </Alert>;
        }
        return (
            <Container fluid>
                <h3>{this.props.title}</h3>
                <Row>
                    <Form>
                        <Col>
                            <Form.Group className="mb-3" controlId="form.ControlTextareaInput">
                                <Form.Label>Input</Form.Label>
                                <Form.Control as="textarea" rows={10} onChange={this.handleChange} placeholder={this.props.inputPlaceHolder}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="form.ControlTextareaOutput">
                                <Form.Label>Output</Form.Label>
                                <span> </span>
                                <Button variant={this.state.variantClipboard} size="sm" className="mb-2" onClick={this.handleClickClipboard}>
                                    {this.state.copied ? <BsClipboardCheck /> : <BsClipboard />}
                                </Button>
                                <Form.Control as="textarea" rows={10} value={this.state.output} readOnly/>
                            </Form.Group>
                        </Col>
                    </Form>
                </Row>
                {alert}
            </Container>
        );
    }
}
  
  export default CommonPage;