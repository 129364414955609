import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'

class Ping extends Component {
    render() {
        return (
           <div>
               <h3>JSON Extractor</h3>
               JSON Extractor
           </div>
        );
    }
}

export default Ping;