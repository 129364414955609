import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Row, Col, Stack } from 'react-bootstrap';

class About extends Component {
    render() {
        const title = "About";
        const description = "I'm developer. More specifically, DevOps Engineer. " +
                            "I created this space to help with simple but essential things when developing or operating a system. " +
                            "I will continue to develop in the hope that this space will be helpful to many developers.";
        const navsJSON = [
            {title: "JSON Pretty", path: "/json/pretty", description: "Converts JSON format beautifully. This converted format is easier to view and helps with development."},
            {title: "JSON To YAML", path: "/json/yaml", description: "Converts JSON format to YAML format. Change the JSON format to YAML format and use it wherever you need it."},
        ];
        const navsReplace = [
            {title: "Replace Newline", path: "/replace", description: "Words or sentences separated by new lines are displayed as a single line by inserting the selected character in the middle."},
        ];
        const navsGenerator = [
            {title: "Password Generator", path: "/generate/password", description: "Generates strong passwords using an authenticated encryption algorithm. Passwords generated here will never be leaked. Please use it with confidence."},
            {title: "Base64 Encoding & Decoding", path: "/generate/base64", description: "Encodes data for base64 and decodes base64 data."},
            {title: "Millisecond to Date", path: "generate/millisecond", description: "Generates milliseconds to current timestamp."},
        ];

        return (
            <Container>
                <h2>{title}</h2>
                <Row>
                    <Col>{description}</Col>
                </Row>
                <hr />
                <Stack gap={3}>
                    <Row xs={1} md={1}>
                        <h5>JSON</h5>
                        {navsJSON.map((nav, idx) => (
                            <Col className="mb-1">
                                <Card>
                                    <Link to={nav.path}>
                                        <Card.Body>
                                            <Card.Title>{nav.title}</Card.Title>
                                            <Card.Text>{nav.description}</Card.Text>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row xs={1} md={1}>
                        <h5>Replace</h5>
                        {navsReplace.map((nav, idx) => (
                            <Col className="mb-1">
                                <Card>
                                    <Link to={nav.path}>
                                        <Card.Body>
                                            <Card.Title>{nav.title}</Card.Title>
                                            <Card.Text>{nav.description}</Card.Text>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>
                        ))}
                    </Row>                    
                    <Row xs={1} md={1}>
                        <h5>Generator</h5>
                        {navsGenerator.map((nav, idx) => (
                            <Col className="mb-1">
                                <Card>
                                    <Link to={nav.path}>
                                        <Card.Body>
                                            <Card.Title>{nav.title}</Card.Title>
                                            <Card.Text>{nav.description}</Card.Text>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Stack>
            </Container>
        );
    }
}
  
export default About;