import React, { Component } from 'react';
import CommonPage from '../common/CommonPage'

function JSONPretty() {
    return (
        <CommonPage 
            title='JSON Pretty'
            apiUrl='/json/pretty'
            inputPlaceHolder='{&#13;"type":"fruit",&#13;"name":"banana"&#13;}' 
        />
    );
}

export default JSONPretty;