import React, { Component } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

class GeneratePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'MD5',
            output: 'Please input value...',
            valid: true,
            variantClipboard: 'secondary',
            copied: false,
        };
        this.timeout = 0;
    
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handlerClickClipboard = this.handlerClickClipboard.bind(this);
    }

    handleChange = (event) => {
        this.state.variantClipboard = 'secondary';

        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            axios.post('/generate/password', 
            {
                type: this.state.type,
                input: event.target.value
            }).then(response => {
                this.setState({
                    output: response.data.output,
                    valid: response.data.valid,
                })
            }).catch((err) => {
                console.log(err);
            });
          }, 500);
    };

    handleCheckChange = (event) => {
        this.state.type = event.target.value
    }
    
    handlerClickClipboard() {
        navigator.clipboard.writeText(this.state.output);
        this.setState(() => ({
            copied: true,
            variantClipboard: 'success',
        }));
    }

    render() {
        const valid = this.state.valid;
        let alert;
        if (!valid) {
            alert = <Alert variant="danger" dismissible>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        {this.state.output}
                    </Alert>;
        }
        return (
            <Container fluid>
                <h3>Password Generator</h3>
                <Row>
                    <Form>
                        <Col>
                            <Form.Group className="mb-3" controlId="form.ControlCheck">
                                {['MD5','SHA512'].map((name) => (
                                    <Form.Check
                                        inline
                                        defaultChecked={name === "MD5"}
                                        type="radio"
                                        id={`${name}`}
                                        label={`${name}`}
                                        name="type"
                                        value={`${name}`}
                                        onChange={this.handleCheckChange}
                                    />
                                ))}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="form.ControlTextareaInput">
                                <Button variant="primary" onClick={this.handleChange}>Generate</Button>{' '}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="form.ControlTextareaOutput">
                                <Form.Label>Output</Form.Label>
                                <span> </span>
                                <Button variant={this.state.variantClipboard} size="sm" className="mb-2" onClick={this.handlerClickClipboard}>
                                    {this.state.copied ? <BsClipboardCheck /> : <BsClipboard />}
                                </Button>
                                <Form.Control as="textarea" rows={10} value={this.state.output} readOnly/>
                            </Form.Group>
                        </Col>
                    </Form>
                </Row>
                {alert}
            </Container>
        );
    }
}
  
  export default GeneratePassword;