import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

var style = {
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "5px",
    height: "50px",
    width: "100%",
}

function OffCanvasOpensourceLicense({ ...props }) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="light" onClick={handleShow} className="me-2">
            OSS
        </Button>
        <Offcanvas show={show} onHide={handleClose} {...props}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>OSS Notice</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item><a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a></ListGroup.Item>
                    <ListGroup.Item><a href="https://react-bootstrap.github.io/" target="_blank" rel="noopener noreferrer">React Bootstrap</a></ListGroup.Item>
                    <ListGroup.Item><a href="https://react-icons.github.io/react-icons/" target="_blank" rel="noopener noreferrer">React Icons</a></ListGroup.Item>
                    <ListGroup.Item><a href="https://go.dev/" target="_blank" rel="noopener noreferrer">Go Programming Language</a></ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

const Footer = () => <footer className="page-footer font-small blue pt-4">
    <div className="footer-copyright text-center py-3 text-dark-50 bg-light" style={style}>© {new Date().getFullYear()} Copyright:
        <a href="https://formatpretty.com/"> formatpretty.com</a>
        <span>     </span>
        <OffCanvasOpensourceLicense className="text-center py-3" placement='end' />
    </div>
</footer>

export default Footer